import { signIn } from "next-auth/react"

export default async function signInToAzure(
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  username?: string,
  redirect?: string
) {
  if (!username || username.length === 0) return
  setIsLoading(true)
  const options = {} as Record<string, any>

  console.log("trying to login with username: " + username)

  const response = await fetch("/api/user/hint?username=" + username)
  const json = await response.json()
  const fullusername = json.upn
  const isEnabled = json.enabled
  if (!isEnabled) {
    setIsLoading(false)
  }

  if (username) {
    options.login_hint = fullusername
  }
  if (redirect) {
    options.callbackUrl = redirect
  }
  signIn("azure-ad", undefined, options)
}
