/* eslint-disable @next/next/no-img-element */
"use client"
import { AtSign, Code, FileQuestion, Ruler, SearchCheck } from "lucide-react"
import { useRouter } from "next/navigation"

export function Resources() {
  const router = useRouter()
  return (
    <div className="mt-5 flex-col space-y-4">
      <h2 className="mt-6 text-2xl font-semibold">PARS Resources</h2>
      <div className="grid grid-cols-2 gap-4">
        <button
          className="flex w-[400px] cursor-pointer items-center space-x-2 rounded-lg bg-white p-4 hover:shadow-lg dark:bg-gray-800"
          onClick={() => router.push("https://json.pars.doe.gov")}
        >
          <Code className="h-6" />
          <div className="ml-4 font-semibold">
            PARS CPP JSON Schema Documentation
          </div>
        </button>
        <button
          className="flex w-[400px] cursor-pointer items-center space-x-2 rounded-lg bg-white p-4 hover:shadow-lg dark:bg-gray-800"
          onClick={() => router.push("https://wiki.pars.doe.gov")}
        >
          <SearchCheck className="h-6" />
          <div className="ml-4 font-semibold">PARS Data Quality Wiki</div>
        </button>
        <button
          className="flex w-[400px] cursor-pointer items-center space-x-2 rounded-lg bg-white p-4 hover:shadow-lg dark:bg-gray-800"
          onClick={() =>
            router.push(
              "https://support.pars.doe.gov/support/solutions/folders/70000379689"
            )
          }
        >
          <FileQuestion className="h-6" />
          <div className="ml-4 font-semibold">PARS FAQs</div>
        </button>
        <button
          className="flex w-[400px] cursor-pointer items-center space-x-2 rounded-lg bg-white p-4 hover:shadow-lg dark:bg-gray-800"
          onClick={() =>
            router.push("https://support.pars.doe.gov/support/tickets/new")
          }
        >
          <AtSign className="h-6" />
          <div className="ml-4 font-semibold">Request a PARS Account</div>
        </button>

        <button
          className="flex w-[400px] cursor-pointer items-center space-x-2 rounded-lg bg-white p-4 hover:shadow-lg dark:bg-gray-800"
          onClick={() => router.push("https://ip2m.pars.doe.gov")}
        >
          <Ruler className="h-6" />
          <div className="ml-4 font-semibold">Login to IP2M METRR</div>
        </button>
      </div>
    </div>
  )
}
