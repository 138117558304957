/* eslint-disable @next/next/no-img-element */
"use client"
export function SecurityNotice() {
  return (
    <div className="mt-5 max-w-[800px] rounded-lg border-2 border-black bg-white p-4 dark:bg-gray-800">
      <h2 className="text-xl font-semibold">Security Notice</h2>
      <p className="mt-2 text-xs text-gray-700 dark:text-gray-300">
        {`This is a Federal Government system for the U.S. Department of
            Energy. Users will be held accountable for their actions while using
            the PARS application. Any unauthorized access or inappropriate use
            of this application is prohibited and could be subject to criminal
            or civil penalties.`}
      </p>
      <p className="mt-2 text-xs text-gray-700 dark:text-gray-300">
        {`Users are granted access to the PARS application based on a need 
            to access specific information. Users are not to attempt to access 
            information to which access has not been authorized. All attempts to
             access this system are logged and unauthorized access will be prosecuted.`}
      </p>
    </div>
  )
}
