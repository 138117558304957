import * as React from "react"

import { cn } from "@/app/lib"
import { InputNumberFormat } from "@react-input/number-format"
import utcToZonedTime from "date-fns-tz/utcToZonedTime"
import format from "date-fns/format"
import { InputHTMLAttributes, forwardRef, useEffect, useState } from "react"
import { UseFormReturn } from "react-hook-form"
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./form"

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, hidden, ...props }, ref) => (
    <input
      type={type}
      hidden={hidden}
      pattern={props.pattern}
      className={cn(
        "flex h-10 w-full rounded-md border border-slate-200  bg-white px-3 py-2 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50  dark:border-slate-800 dark:bg-slate-950 dark:ring-offset-slate-950 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300",
        className
      )}
      ref={ref}
      {...props}
    />
  )
)
Input.displayName = "Input"

// A debounced input react component
function DebouncedInput({
  className,
  type,
  value: initialValue,
  onChange,
  debounce = 300,
  ...props
}: {
  readonly value: string | number
  readonly onChange: (value: string | number) => void
  readonly debounce?: number
} & Omit<InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value, onChange, debounce])

  return (
    <input
      type={type}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      className={cn(
        "flex h-10 w-full rounded-md border border-slate-200  bg-white px-3 py-2 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50  dark:border-slate-800 dark:bg-slate-950 dark:ring-offset-slate-950 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300",
        className
      )}
      {...props}
    />
  )
}

function FormInput({
  className,
  form,
  field,
  placeholder,
  label,
  ...props
}: InputProps & { readonly field: string; readonly label?: string } & {
  readonly form: UseFormReturn<any, any>
}) {
  return (
    <FormField
      control={form.control}
      name={field}
      render={({ field }) => (
        <FormItem>
          <FormControl>
            <>
              {label ? <FormLabel>{label}</FormLabel> : null}
              <Input
                {...field}
                value={field.value ?? ""}
                className={cn(
                  "my-0 flex h-8 w-[90px] rounded-md border border-slate-200 bg-white  px-3 py-2 text-xs  ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50  dark:border-slate-800 dark:bg-slate-950 dark:ring-offset-slate-950 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300",
                  className
                )}
                placeholder={placeholder}
                disabled={props.disabled}
                type={props.type}
                readOnly={props.readOnly}
              />
            </>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

function cleanNumericInputValue(value: string) {
  if (value == "") {
    return null
  }
  // value = value.replace(",", "")
  if (isNaN(parseFloat(value))) {
    return value
  }
  return parseFloat(value)
}

/**
 *
 * @param className Used for Tailwind styling
 * @param form The form object
 * @param field  The field in the form to set the value to
 * @param blankIsNull  If true, return null if the user inputs no value; otherwise, return zero
 * @returns
 */
function FormNumberInput({
  className,
  form,
  field,
  blankIsNull = false,
  ...props
}: InputProps & {
  readonly field: string
  readonly blankIsNull?: boolean
} & {
  readonly form: UseFormReturn<any, any>
}) {
  const fieldName = field

  return (
    <FormField
      name={field}
      render={({ field }) => (
        <FormItem>
          <FormControl>
            <InputNumberFormat
              locales={"en"}
              maxLength={props.maxLength}
              disabled={props.disabled}
              onNumberFormat={(e) => {
                // if blankIsNull = true and the user inputs no value, we want to return null (instead of 0, which is what e.detail.number would show)
                const val: number | null =
                  e.detail.value.trim() == "" && blankIsNull
                    ? null
                    : e.detail.number

                // Update the form value
                form.setValue(fieldName, val)
              }}
              onChange={(e) => {
                // Update the form value
                form.setValue(fieldName, e.target.value)
              }}
              defaultValue={
                field.value
                  ? new Intl.NumberFormat("en-US", {
                      maximumFractionDigits: 2,
                    }).format(field.value)
                  : ""
              }
              className={cn(
                "my-0 flex h-8 w-[90px] rounded-md border border-slate-200 bg-white px-3 py-2 text-xs ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-800 dark:bg-slate-950 dark:ring-offset-slate-950 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300",
                className
              )}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

function cleanDateInputValue(value: string) {
  if (value == "") {
    return null
  }

  return value
}

function FormDateInput({
  className,
  form,
  field,
  ...props
}: InputProps & { readonly field: string } & {
  readonly form: UseFormReturn<any, any>
}) {
  return (
    <FormField
      control={form.control}
      name={field}
      render={({ field }) => (
        <FormItem>
          <FormControl>
            <Input
              {...field}
              value={(() => {
                if (!field.value) {
                  return ""
                }
                return format(utcToZonedTime(field.value, "UTC"), "yyyy-MM-dd")
              })()}
              placeholder="mm/dd/yyyy"
              onBlur={(e) => {
                field.onChange(cleanDateInputValue(e.target.value))
              }}
              type="date"
              className={cn(
                "h-8 w-[120px] text-xs placeholder:-ml-2 placeholder:tracking-tight",
                className
              )}
              disabled={props.disabled}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

export { DebouncedInput, FormDateInput, FormInput, FormNumberInput, Input }
